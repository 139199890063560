<template>
  <ValidationProvider
    tag="div"
    class="question single-answer column"
    :class="width"
    :rules="validationRules"
    v-slot="{ errors }"
    v-if="!reviewMode"
  >
    <div class="question-text has-text-weight-bold">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content">
      <b-field>
        <b-datetimepicker
          :placeholder="$t('datetime.placeholder')"
          icon="calendar-today"
          :locale="$t('locale')"
          horizontal-time-picker
          v-bind="{...customizedProps}"
          @input="onChange"
          v-model="data"
        >
        </b-datetimepicker>
      </b-field>
    </div>
  </ValidationProvider>
  <div class="question" v-else>
    <div class="is-flex is-align-items-center">
      <div class="question-text has-text-weight-bold column p-0">
        <p class="question-text__code" v-if="showQuestionCode">
          {{ question.code }}
        </p>
        <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      </div>
      <b-button type="is-text" @click="$emit('edit')">
        <b-icon icon="pencil" type="is-primary"></b-icon>
      </b-button>
    </div>
    <div class="question-content">
      <b-field>
        <b-datetimepicker
          :placeholder="$t('datetime.placeholder')"
          :locale="$t('locale')"
          v-bind="{...customizedProps}"
          :disabled="loading"
          v-model="data"
          class="datetime-input"
        >
        </b-datetimepicker>
      </b-field>
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from "@/mixins/question";
import { UnFocusMixin } from "@/mixins/unfocus";
export default {
  name: "DatetimePicker",
  mixins: [QuestionMixin, UnFocusMixin],
  data() {
    return {
      data: null,
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", { [this.question.qid]: value?.toISOString() });
    },
  },
  created() {
    setTimeout(() => {
      const value = this.value[this.question.qid];
      if (value) {
        this.data = new Date(value);
        this.unFocus();
      }
    }, 300);
  },
};
</script>

<style lang="scss">
.datepicker.datetime-input {
  .dropdown {
    cursor: default;
  }
  input{
    background-color: white;
    border-color: #e5e5e5;
    border-radius: 4px;
    color: #363636;
  }
}
</style>
